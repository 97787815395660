.Result {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.1) 20%,
    rgba(56, 131, 250, 0.05) 70%
  );
  .ResultContent {
    width: 1270px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    height: 100%;

    .ResultBox {
      width: 1070px;
      font-size: 14px;

      .ResultHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 32px;
        line-height: 32px;
        span {
          margin-right: 20px;
        }
        .ResultHeaderLeft {
          display: flex;
          justify-content: center;
          align-items: center;
          .ResultError {
            font-size: 14px;
            color: rgb(255, 112, 112);
            margin-left: 10px;
          }
          .MarkdownCheckbox {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-left: 20px;
            cursor: pointer;
            input {
              margin-right: 6px;
            }
          }
        }
        .ResultHeaderRight {
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
      }
      .ResultText {
        padding: 0 10px 0 10px;
        border: 1px solid #8661ff;
        height: 500px;
        overflow-y: auto;
        background-color: #fafafa;
        cursor: default;
      }
      .ResultPre {
        white-space: pre-wrap;
        font-family: Monaco;
      }
      .ResultMarkdown {
        margin-top: 14px;
      }
    }
    .ResultControls {
      margin-left: 30px;
      width: 200px;
      > span {
        font-size: 32px;
        line-height: 36px;
      }
      .ResultControlsContents {
        padding: 10px;
        border: 1px solid #8661ff;
        background-color: white;
        margin: 16px 0 0 0;
        height: 480px;
        .ResultControl {
          margin-bottom: 14px;
          input[type="text"] {
            padding: 5px;
            width: calc(100% - 10px);
          }
          .inputContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // line-height: 24px;
            input {
              width: 30px;
              text-align: right;
            }
          }
          select,
          input[type="range"] {
            width: 100%;
            margin-top: 5px;
            box-sizing: border-box;
          }
          select {
            padding: 6px 10px;
          }
        }
      }
    }
  }

  .historyDropdown {
    appearance: none;
    border-radius: 0;
    align-self: center;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid #8661ff;
    padding: 7px 20px 7px 10px;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 4px;
  }
}
