.ImportWrap {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.1) 20%,
    rgba(56, 131, 250, 0.05) 70%
  );
  .Import {
    width: 1270px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 40px;
    margin-bottom: 40px;

    .jsoneditor {
      border: 1px solid #8661ff;
    }
    .jsoneditor-menu {
      background-color: #8661ff;
      button {
        color: white;
        &:hover {
          color: black;
        }
      }
      // .jsoneditor-modal .pico-modal-header {
      //   background: #8661ff;
      // }
    }

    .ImportHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      span {
        margin-right: 20px;
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
}
