.primaryButton {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  border: 2px solid hsla(0, 2%, 91%, 0.8);
  border-radius: 0;
  color: #fff;
  opacity: 1;
  padding: 8px 30px;
  background: linear-gradient(
    200deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.primaryButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    20deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  // background: #8661ff;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.primaryButton:hover {
  &:before {
    opacity: 0;
  }
}

.blueButton {
  display: block;
  font-size: 16px;
  position: relative;
  border: 2px solid hsla(0, 2%, 91%, 0.8);
  // border: 2px solid #8661ff;
  border-radius: 0;
  color: #fff;
  opacity: 1;
  padding: 8px 44px;
  background: linear-gradient(
    200deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.blueButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #8661ff;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.blueButton:hover {
  &:before {
    opacity: 0;
  }
}

.blueButton:disabled {
  &:before {
    background: gray !important;
  }
  cursor: not-allowed;
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
