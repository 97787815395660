.Header {
  width: 100%;
  height: 62px;
  background-color: #8661ff;
  opacity: 0.9;
  // background-color: rgba(160, 120, 250, 1);
  // background: linear-gradient(
  //   20deg,
  //   rgba(255, 0, 255, 0.3) 20%,
  //   rgba(0, 255, 255, 0.2) 75%
  // );
}

.HeaderText {
  float: left;
  font-size: 32px;
  line-height: 40px;
  margin: 10px 20px;
  color: white;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 3px;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.HeaderButton {
  border: 1px solid red;
  line-height: 28px;
  width: 100px;
  height: 30px;
  margin: 10px 20px;
  box-sizing: border-box;
  float: left;
  background-color: white;
  cursor: pointer;
}

.HeaderButton:hover {
  background-color: red;
}

@media screen and (max-width: 500px) {
  .HeaderButton {
    width: 70px;
    margin: 10px;
  }
}

.HeaderLeft {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
}

.HeaderRight {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
}

.signOut {
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: white;
  padding: 5px;
  /* border: 1px solid white;
    border-radius: 5px; */
}
a {
  text-decoration: none;
}

// .upgrade {
//   background-color: white;
//   padding: 10px;
//   margin-left: 30px;
//   border-radius: 5px;
//   position: relative;
// }

.upgrade {
  margin-left: 30px;
  font-size: 16px;
  // font-weight: 500;
  position: relative;
  border: 2px solid hsla(0, 2%, 91%, 0.8);
  border-radius: 0;
  color: #fff;
  opacity: 1;
  padding: 8px 30px 10px 30px;
  background: linear-gradient(
    200deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.upgrade:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    20deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(212, 175, 55, 0.6) 75%
  );
  // background: #8661ff;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.upgrade:hover {
  &:before {
    opacity: 0;
  }
}
