.EditorMain {
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.1) 20%,
    rgba(56, 131, 250, 0.05) 70%
  );
  .EditorInputs {
    width: 1270px;
    margin: 0 auto;
    padding: 0 10px;
    margin-bottom: 40px;
    margin-top: 40px;
    margin-bottom: 100px;
    .EditorContent {
      display: flex;
      justify-content: space-between;

      .EditorTextarea {
        width: 640px;
        &:first-of-type {
          margin-right: 28px;
        }
        font-size: 14px;
        .EditorTextareaHeader {
          font-size: 32px;
          line-height: 60px;
        }

        .EditorAce {
          background-color: white;
          // padding: 5px 0 0 10px;
          padding-left: 5px;
          border: 1px solid #8661ff;
          &-error {
            border-color: red;
          }
          // &-disabled {
          //   background-color: gray;
          // }
          .ace_gutter {
            z-index: 0;
          }
        }
        .EditorPreview {
          background-color: #fafafa;
          cursor: default;
          padding: 0 10px 0 10px;
          border: 1px solid #8661ff;
          height: 500px;
          overflow-y: auto;
          white-space: pre-wrap;
          font-family: Monaco;
        }
      }
    }
  }
  .EditorControls {
    width: 20%;
  }
}
